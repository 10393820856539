@import url("https://fonts.googleapis.com/css2?family=Noto+Serif:wght@400;700&display=swap");

:root {
  --primary: #2b2726;
  --secondary: #4e4e4e;
  --error: #ff4a4a;
}

/* base styles & title */
body {
  font-family: "Noto Serif";
  color: var(--secondary);
  background-color: #d6d5d5;
}

.title h1 {
  color: var(--primary);
  font-size: 1.2rem;
  letter-spacing: 2px;
  font-weight: normal;
}
.title h2 {
  text-align: center;
}
.title p {
  text-align: center;
}

.title h2 {
  margin-top: 60px;
  font-size: 2.6rem;
}

.output {
  height: 60px;
  font-size: 0.8rem;
}
.error {
  color: var(--error);
}

/* progress bar styles */
.progress-bar {
  height: 5px;
  background: var(--primary);
  margin-top: 20px;
}

