/* image grid styles */
.img-shoes  {
    width: 100%;
    height: 80%;
  }

  .img-wrap {
    overflow: hidden;
    width: 100%;
    height: 500%;
  }
  .img-wrap img {
    min-width: 10%;
    min-height: 10%;
    max-width: 20%;
    max-height: 20%;
  
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
 
  /* modal styles */
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
  }
  .backdrop img {
    display: block;
    width: auto;
    height: 80%;
    margin: 100px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
  }
  .title-shoes {
    text-align: center;
    font-family:"Century Gothic", Arial, sans-serif;
    font-weight: 500;
    padding-bottom: 0.5%;
    padding-top: 0.5%;
    background-color: #2b2726;
    size: inherit;
    color: aliceblue;
    
  }
  .title-data {
    font-family:"Century Gothic", Arial, sans-serif !important;
  }
  
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
  @media (max-width: 1000px) {
    .ifram {
      width: 40vh;
    height: 60%;
      height: 80vh;
    }
    .data {
      width: 40vh;
     margin-top: 10%;
    }
    .title-shoes {
      width: 40vh;
    }
    .img-shoes  {
      margin-top: 8%;
      width: 40vh;
    }
  
  }