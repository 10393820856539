/* image grid styles */
.grid-container {
  align-items: center;
  justify-content: center;
  margin-bottom: 5%;
  margin-top: 15vh;
}
.img-gallery {
    height: 48vh;
    width: 35vh;
  }
  .gallery-div {
    display: flex;
    justify-content: center;
  }
  .gallery-div-center{
    width: 80% ;
  }
  .img-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  .img-wrap {
    overflow: hidden;
    width: 100%;
    height: 500%;
  }
  .img-wrap img {
    min-width: 10%;
    min-height: 10%;
    max-width: 20%;
    max-height: 20%;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
  }
 
  /* modal styles */
  .backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    
  }
  .backdrop img {
    display: block;
    width: auto;
    height: 80%;
    margin: 100px auto;
    box-shadow: 3px 5px 7px rgba(0, 0, 0, 0.5);
    border: 3px solid white;
  }
  
  .title-photo {
    text-align: center;
    font-family:"Century Gothic", Arial, sans-serif;
    font-weight: 500;
    font-size: 18px;
    margin-top: 1%;
    margin-bottom: 12%;
    background-color: #2b2726;
    size: inherit;
    color: aliceblue;
  }


  